import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { showErrorAlert } from '../../../shared/components/alerts/alert.decorator';
import { LoaderEnabled } from '../../services/loader.service';
import {
  DeregisterReasonLocalizedMessage,
  DeregisterReasons,
  FilterKkmsRequest,
  ITotalCount,
  KkmAvailableOperations,
  Report,
  RetailPlacesWithKkmsRequest,
  SearchTicketResponse,
  TransactionsResponse,
} from './kkms.types';
import { IKkm, IRetailPlace } from '../retail-places/retail-paces.types';
import { setMessageIsNotError } from '../../errors-interceptor';

@Injectable({
  providedIn: 'root',
})

export class KkmsApiService {
  constructor(private httpClient: HttpClient) {}

  // Получить pdf-договор при подписании регистрации/дерегистрации ККМ
  fetchLinkToPdfKKkmChangeStatusDocument(pid: string): string {
    return `/api/process/pid/${pid}/registration-application`;
  }

  // Получить base64 (для подписания изменения статуса ККМ с помощью ЭЦП)
  fetchKkmChangeStatusDocument(pid: string): Observable<string> {
    return this.httpClient.get(`/api/process/pid/${pid}/registration-application/base64`, {
      responseType: 'text',
    });
  }

  // Получить заявление на регистрацию в base64
  fetchKkmRegistrationDocument(pid: string): Observable<string> {
    return this.httpClient.get(`/api/process/pid/${pid}/tax-registration-application/base64`, {
      responseType: 'text',
    });
  }

  // Получить ссылку на pdf заявление на регистрацию
  fetchLinkToPdfKkmRegistrationDocument(pid: string): string {
    return `/api/process/pid/${pid}/tax-registration-application`;
  }

  // Получить список причин для снятия ККМ с учета
  fetchDelReasons(): Observable<DeregisterReasons> {
    return this.httpClient.get<DeregisterReasons>('api/kkms/delReasons');
  }

  fetchToken(kkmId: string): Observable<any> {
    return this.httpClient.get(`/api/kkms/${kkmId}/reset-token`);
  }

  // Получить причину снятия ККМ с учёта (null в случае отсутствия причины)
  fetchDelReason(id: string): Observable<DeregisterReasonLocalizedMessage> {
    return this.httpClient.get<DeregisterReasonLocalizedMessage>(`/api/kkms/delReason?id=${id}`);
  }

  @showErrorAlert()
  fetchStatusesCount(): Observable<ITotalCount> {
    return this.httpClient.get<ITotalCount>('/api/kkms/totalcount');
  }

  fetchKkmIsnaModels(): Observable<string[]> {
    return this.httpClient.get<string[]>('api/taxpayer/kkms/models');
  }

  fetchKkmInisModels(): Observable<string[]> {
    return this.httpClient.get<string[]>('api/kkms/models/inis');
  }

  fetchKkmsId(): Observable<number[]> {
    return this.httpClient.get<number[]>('/api/kkms/ids');
  }

  @showErrorAlert()
  @LoaderEnabled()
  fetchKkmsByStatus(data: FilterKkmsRequest): Observable<Array<IRetailPlace>> {
    return this.httpClient.post<Array<IRetailPlace>>('/api/kkms/filter-kkms', data);
  }

  @showErrorAlert()
  @LoaderEnabled()
  fetchRetailPlacesWithKkms(data: RetailPlacesWithKkmsRequest): Observable<IRetailPlace[]> {
    return this.httpClient.post<IRetailPlace[]>('/api/kkms/search-retail-place-with-kkm', data);
  }

  @showErrorAlert()
  @LoaderEnabled()
  fetchKkmsByCriteria(data: any): Observable<Array<IRetailPlace>> {
    return this.httpClient.post<Array<IRetailPlace>>('/api/kkms/search-kkms', data);
  }

  @LoaderEnabled()
  fetchKkmsIdByCriteria(data: RetailPlacesWithKkmsRequest): Observable<number[]> {
    return this.httpClient.post<number[]>('/api/kkms/search-kkm-id-with-filter', data);
  }

  @LoaderEnabled()
  throwDeclineReason(id: string): Observable<string> {
    return this.httpClient.get<{ message: string }>(`/api/taxpayer/kkms/decline-reasons?ids=${id}`, {
      context: setMessageIsNotError(),
    }).pipe(
      map(res => res.message.replace(/\\"/g, '"')), // убираем экранирование кавычек из текста сообщения,
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) return of(String(error?.error.message ?? ''));
        return throwError(error);
      }),
    );
  }

  @LoaderEnabled()
  setNewTitle(kkmId: number, title: string): Observable<void> {
    return this.httpClient.post<void>(`/api/kkms/${kkmId}/update-name`, {
      newKkmName: title,
    });
  }

  @LoaderEnabled()
  updateKkm(kkmId: number, kkmName: string, subscriberId?: string | null): Observable<void> {
    return this.httpClient.post<void>(`/api/kkms/${kkmId}/update`, {
      kkmName,
      ...(subscriberId && {
        subscriberId,
      }),
    });
  }

  // Swagger врёт. В swagger указано, что возвращается тип Ticket, но это не так. Возвращается какая-то совсем другая модель которой нет в свагере
  @LoaderEnabled()
  fetchTransactionsByParams(kkmId: number, ticketNumber: string = ''): Observable<SearchTicketResponse> {
    return this.httpClient.post<SearchTicketResponse>('api/kkms/search-ticket', {
      kkmId,
      ticketNumber,
    });
  }

  @LoaderEnabled()
  fetchReportsOld(kkmId: number, params: HttpParams): Observable<Report[]> {
    return this.httpClient.get<Report[]>(`/api/kkms/${kkmId}/reports`, {
      params,
    });
  }

  @LoaderEnabled()
  fetchReports(kkmId: number, params: HttpParams): Observable<Report[]> {
    return this.httpClient.get<Report[]>(`/api/taxpayer/kkms/${kkmId}/reports`, {
      params,
    });
  }

  fetchTransactionsOld(kkmId: number, params: HttpParams): Observable<TransactionsResponse> {
    return this.httpClient.get<TransactionsResponse>(`/api/kkms/${kkmId}/transactions`, {
      params,
    });
  }

  fetchTransactions(kkmId: number, params: HttpParams): Observable<TransactionsResponse> {
    return this.httpClient.get<TransactionsResponse>(`/api/taxpayer/kkms/${kkmId}/transactions`, {
      params,
    });
  }

  fetchKkm(kkmId: number): Observable<IKkm & KkmAvailableOperations> {
    return this.httpClient.get<IKkm & KkmAvailableOperations>(`/api/kkms/${kkmId}`);
  }
}
